<template>
	<div class="mainMenuBar" id="mainMenuBar" :class="{ sticky: stickyNavActive }">
		<Menubar :model="items">
			<template #start>
				<img src="@/assets/ppg_logo_white.svg" height="40" class="p-mr-2" />
			</template>

			<template #end>
				<Button v-if="isAllowed('useradmin') && 1 == 2" :label="'Users'" class="p-button-outlined p-button-sm" icon="pi pi-users" style="background:#fff;" onclick="location.href='/users'" />
				<Button :label="'Sign out ' + directusUser.first_name" class="p-button-outlined p-button-sm" icon="pi pi-power-off" style="background:#fff;" @click="signOut()" />
			</template>
		</Menubar>
	</div>
</template>

<script>
import Directus from "@/services/directus/";
import ssclibrary from "@/services/ssclibrary/";

import Menubar from "primevue/menubar";
import Button from "primevue/button";

export default {
	name: "NavBar",
	components: {
		Directus,
		ssclibrary,
		Menubar,
		Button,
	},
	methods: {
		signOut() {
			Directus.signOut(this.$router);
		},

		toggleNavClass() {
			if (this.stickyNavActive == false) {
				return "nav";
			} else {
				return "sticky-nav";
			}
		},

		setLanguage(event) {
			if (event.item && event.item.code) {
				Directus.client.api.patch("/items/user/" + this.directusUser.id, { language_id: 3 });
			}
		},

		isAllowed(func) {
			return ssclibrary.isAllowed(func);
		},
	},
	data() {
		return {
			directusUser: {},
			user: {},
			lng: null,
			stickyNavActive: false,
			lastScroll: null,
			items: [],
		};
	},
	mounted() {
		Directus.getCurrentUser().then((response) => {
			this.directusUser = response.data;
		});

		window.document.onscroll = () => {
			let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			let navBar = document.getElementById("mainMenuBar");
			if (window.scrollY > navBar.offsetTop + navBar.offsetHeight) {
				if (this.lastScroll > currentScroll) {
					this.stickyNavActive = true;
				} else {
					this.stickyNavActive = false;
				}
			} else {
				this.stickyNavActive = false;
			}
			this.lastScroll = currentScroll;
		};
	},
	created() {
		//this.user = JSON.parse(this.$cookie.get('sscLibraryUser'));
		//this.lng = this.user.data.language_id.code;

		this.items = [
			{
				icon: "pi pi-home",
				command: (event) => {
					location.href = "/";
				},
				label: "PPG Contract Manager",
			},

			/*

               {
                  label: this.user.data.language_id.language,
                  icon:'pi pi-fw pi-globe',
                  items:[
                     {
                        label:'English',
                        code:'en-US',
                        command: this.setLanguage
                     },
                     {
                        label:'Français',
                        code:'fr-FR',
                        command: this.setLanguage
                     },
                     {
                        label:'Nederlands',
                        code:'nl-NL',
                        command: this.setLanguage
                     },

                  ]
               }
               */
		];
	},
};
</script>

<style lang="scss">
.mainMenuBar {
	transition: 100ms;
	width: 100%;
	top: 0px;
}

.mainMenuBar.sticky {
	top: 0px;
	position: fixed;
	z-index: 1000;
}

.p-menubar-root-list {
	background: #1173a4 !important;
}

.mainMenuBar {
	.p-menubar {
		background: #1173a4;
		border: 0px;
		border-radius: 0px;
	}

	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
		color: #eaeaea;
	}

	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
		color: #60c8d0;
		margin-right: 0.5rem;
	}
}
</style>
