<template>
  <div class="LayoutDefault">

    <NavBar/>
     
    <div>
        <div>
          <slot/>

        </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/layout/NavBar'


export default {
    components: {
        NavBar
    }
}
</script>

<style scoped>


</style>

