import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueLodash from "vue-lodash";
import lodash from "lodash";

import "@/scss/main.scss";
import "font-awesome/scss/font-awesome.scss";

Vue.config.productionTip = false;

import ToastService from "primevue/toastservice";
Vue.use(ToastService);

import VueCookie from "vue-cookie";
Vue.use(VueCookie);

Vue.use(VueLodash, { lodash: lodash });

const moment = require("moment");
require("moment/locale/nl");
Vue.use(require("vue-moment"), {
	moment,
});

// usage: {{ file.size | prettyBytes }}
Vue.filter("prettyBytes", function(num) {
	// jacked from: https://github.com/sindresorhus/pretty-bytes
	if (typeof num !== "number" || isNaN(num)) {
		throw new TypeError("Expected a number");
	}

	var exponent;
	var unit;
	var neg = num < 0;
	var units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	if (neg) {
		num = -num;
	}

	if (num < 1) {
		return (neg ? "-" : "") + num + " B";
	}

	exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
	num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
	unit = units[exponent];

	return (neg ? "-" : "") + num + " " + unit;
});

Vue.filter("toCurrency", function(value) {
	if (typeof value !== "number") {
		return value;
	}
	var formatter = new Intl.NumberFormat("nl-NL", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: 2,
	});
	return formatter.format(value);
});

new Vue({
	router,
	render: function(h) {
		return h(App);
	},
}).$mount("#app");
