import VueCookie from "vue-cookie";

const user = JSON.parse(VueCookie.get("sscLibraryUser"));
console.log('user', user)
const lng = 'nl-NL'
if (
	user &&
	user.data &&
	user.data.language_id &&
	user.data.language_id.code
) {
	const lng = user.data.language_id.code
}

export default {

	user,
	lng,

	isAllowed: function (func) {
		if(user && user.allow) {
			let index = _.indexOf(user.allow,  func );
			if (index >= 0) {
				return true
			}
		}
		return false
	},



}