import DirectusSDK from "@directus/sdk-js";
import axios from "axios";
import VueCookie from "vue-cookie";

const client = new DirectusSDK({
	url: "https://ssc-library.brndcntrl.com/directus/public/",
	project: "ssclibrary",
	token: VueCookie.get("directusToken"),
	/*
	persist: true,
	tokenExpirationTime: 5 * 60 * 60 * 1000,
	storage: window.localStorage
	*/
});

export default {
	client,

	signOut(router) {
		client.logout();
		router.push({ name: "login" });
	},

	signIn(router, toast, email, password, token) {
		/*client.login({
			email: email,
			password: password,
		})
		*/

		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

		VueCookie.delete("directusToken");
		VueCookie.delete("sscLibraryUser");
		VueCookie.delete("targafilesApiAccessToken");

		axios
			.post("https://contractmanager.brndcntrl.com/services/directus/auth/", {
				token: token,
				email: email,
				password: password,
			})

			.then((response) => {
				console.log("authresponse", response);

				if (!response.data.auth.error) {
					let directusToken = response.data.auth.token;
					let directusUser = response.data.user;
					//let targafiles = response.data.targafiles;

					VueCookie.set(
						"directusToken",
						directusToken,
						{
							expires: "1d",
						},
						"/"
					);
					VueCookie.set(
						"sscLibraryUser",
						JSON.stringify(directusUser),
						{
							expires: "1d",
						},
						"/"
					);
					/*
					VueCookie.set(
						"targafilesApiAccessToken",
						JSON.stringify(targafiles),
						{
							expires: "1d",
						},
						"/"
					);
					*/

					location.href = "/";
				} else {
					toast.add({
						severity: "error",
						summary: "Sign in",
						detail: response.data.auth.error.message,
						life: 6000,
					});
				}
			});
	},

	getLocalstorage() {
		var json = {};
		var str = window.localStorage.getItem("directus-sdk-js");
		if (str) {
			json = JSON.parse(str);
		}
		return json;
	},

	getCurrentUser() {
		return client.getMe().then((response) => {
			return response;
		});
	},
};
