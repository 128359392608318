import Vue from "vue";
import VueRouter from "vue-router";
import LayoutDefault from "../layout/Default.vue";
import LayoutLogin from "../layout/Login.vue";

import Home from "../views/home/";
import login from "../views/login/login.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/login",
		name: "login",
		component: login,
		meta: { layout: LayoutLogin, requiresAuth: false },
	},
	{
		path: "/",
		name: "home",
		component: Home,
		meta: { layout: LayoutDefault, requiresAuth: true },
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (Vue.cookie.get("sscLibraryUser") == null) {
			next({
				name: "login",
				params: { nextUrl: to.fullPath },
				replace: true,
			});
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
