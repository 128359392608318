<template>
	<layout-login>
		<div class="background-animation">
			<div class="p-shadow-17" style="width:600px;max-width:90%;margin:100px auto;">
				<Card>
					<template slot="header">
						<div style="text-align:center;"></div>
					</template>
					<template slot="title">
						<div style="text-align:center;" class="p-pb-6">Sign in to PPG Contract Manager</div>
					</template>
					<template slot="content">
						<div>
							<div class="p-fluid">
								<div class="p-field p-grid">
									<label for="email" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">E-mail</label>
									<div class="p-col-12 p-md-9">
										<InputText v-model="user.email" type="text" />
									</div>
								</div>
								<div class="p-field p-grid">
									<label for="password" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">Password</label>
									<div class="p-col-12 p-md-9">
										<InputText v-model="user.password" type="password" />
									</div>
								</div>
							</div>
						</div>
						<Message v-if="loginError" severity="error">{{ loginError }}</Message>
					</template>
					<template slot="footer">
						<div style="text-align:right;">
							<Button class="p-button-lg" :icon="signInBusy == true ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'" label="Sign in" @click="signIn()" />
						</div>
					</template>
				</Card>
			</div>

			<Toast />
		</div>
	</layout-login>
</template>

<script>
import LayoutLogin from "@/layout/Login.vue";
import Directus from "@/services/directus/";

import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";
import Message from "primevue/message";

export default {
	components: {
		LayoutLogin,
		Directus,

		Card,
		Button,
		InputText,
		Toast,
		Message,
	},
	data() {
		return {
			user: {
				email: "",
				password: "",
			},

			loginError: null,
			signInBusy: false,
		};
	},
	props: {
		nextUrl: String,
	},
	methods: {
		signIn() {
			Directus.signIn(this.$router, this.$toast, this.user.email, this.user.password, this.$route.query.token);
		},
	},
	mounted: function() {},
};
</script>

<style>
.background-animation {
	position: absolute;
	right: 0px;
	width: 100%;
	height: 100vh;
	background: linear-gradient(-45deg, #2a7ab0, #23a6d5);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
</style>
