<template>
	<layout-default>
		<div style="background:#FFF;">
			<ProgressSpinner v-if="loading == true"></ProgressSpinner>

			<div class="p-mb-6"></div>
			<div class="uk-container uk-container-expand" v-if="loading == false">
				<DataTable ref="contractsDataTable" :value="contracts" class="p-datatable-striped" :filters="filters" dataKey="id" :autoLayout="true">
					<template #header>
						<div class="table-header">
							<div class="uk-grid">
								<div class="uk-width-expand">
									Contracts
								</div>
								<div class="uk-width-auto">
									<span class="p-buttonset" v-if="filters">
										<Button label="BE" :class="filters['servicecenter_id.address_id.country_id.id'] == 21 ? '' : 'p-button-outlined'" @click="setCountry(21)" />
										<Button label="NL" :class="filters['servicecenter_id.address_id.country_id.id'] == 153 ? '' : 'p-button-outlined'" @click="setCountry(153)" />
									</span>
								</div>
								<div class="uk-width-auto">
									<JsonCSV v-if="tableExportData()" style="display:inline-block;" type="csv" delimiter=";" :data="tableExportData()">
										<Button class="p-button-outlined p-mr-2 p-mb-2" icon="pi pi-download" label="Download .csv" />
									</JsonCSV>
								</div>
							</div>
						</div>
					</template>
					<Column field="servicecenter_id.name" header="Service center" :sortable="true" filterField="servicecenter_id.name" filterMatchMode="contains">
						<template #body="slotProps">
							{{ slotProps.data.servicecenter_id.name }}
						</template>
						<template #filter>
							<InputText type="text" v-model="filters['servicecenter_id.name']" class="p-column-filter" placeholder="" />
						</template>
					</Column>
					<Column v-show="false" field="servicecenter_id.address_id.country_id.id" header="" filterField="servicecenter_id.address_id.country_id.id" filterMatchMode="contains">
						<template #body="slotProps"></template>
					</Column>
					<Column field="hub_name" header="Hub" :sortable="true" filterField="hub_name" filterMatchMode="contains">
						<template #body="slotProps">
							{{ slotProps.data.hub_name }}
						</template>
						<template #filter>
							<InputText type="text" v-model="filters['hub_name']" class="p-column-filter" placeholder="" />
						</template>
					</Column>
					<Column field="name" header="Name contract" :sortable="true" filterMatchMode="contains">
						<template #body="slotProps"> <Button class="p-button-sm p-button-outlined" @click="showContractDetails(slotProps.data, 'details')" :label="slotProps.data.name" /> </template>
						<template #filter>
							<InputText type="text" v-model="filters['name']" class="p-column-filter" placeholder="" />
						</template>
					</Column>
					<Column field="contract_owner_company_id.name" header="Owner" :sortable="true">
						<template #body="slotProps">
							<div v-if="slotProps.data.contract_owner_company_id">
								{{ slotProps.data.contract_owner_company_id.name }}
							</div>
						</template>
					</Column>
					<Column field="contract_owner_company_id.commercial_relationship" header="Commerical Relationship" :sortable="true">
						<template #body="slotProps">
							<div v-if="slotProps.data.contract_owner_company_id">
								<Tag class="p-mr-2" icon="pi pi-check p-mr-1" severity="success" value="Yes" v-if="slotProps.data.contract_owner_company_id.commercial_relationship == 'yes'"></Tag>
								<Tag class="p-mr-2" icon="pi pi-times p-mr-1" severity="danger" value="No" v-else-if="slotProps.data.contract_owner_company_id.commercial_relationship == 'no'"></Tag>
								<Tag class="p-mr-2" icon="pi pi-question p-mr-1" severity="warning" value="Unknown" v-else></Tag>
							</div>
						</template>
					</Column>
					<Column field="date_start" header="Starting date" :sortable="true" filterField="date_start" :filterFunction="filterDate" v-if="1 == 2">
						<template #body="slotProps">
							<div>
								{{ slotProps.data.date_start | moment("D MMMM YYYY") }}
							</div>
						</template>
						<template #filter>
							<Calendar v-model="filters['date_start']" dateFormat="yy-mm-dd" class="p-column-filter" placeholder="" />
						</template>
					</Column>
					<Column field="date_end" header="End of contract" :sortable="true">
						<template #body="slotProps">
							<div>
								{{ slotProps.data.date_end | moment("D MMMM YYYY") }}
							</div>
						</template>
					</Column>
					<Column field="notice_period_date" header="Notice period" :sortable="true">
						<template #body="slotProps">
							<div v-if="slotProps.data.notice_period_date">
								{{ slotProps.data.notice_period_date | moment("D MMMM YYYY") }}
							</div>
						</template>
					</Column>

					<Column field="actionplan_status" header="Action plan" :sortable="true">
						<template #body="slotProps">
							<div v-if="slotProps.data.actionplan_status">
								<Button icon="pi pi-list" :label="slotProps.data.actionplan_status" @click="showContractDetails(slotProps.data, 'actionplan')" class="p-button-outlined p-button-secondary" />
							</div>
						</template>
					</Column>

					<Column field="notification1_days" header="1st Notificaction" :sortable="true">
						<template #body="slotProps">
							<div v-if="slotProps.data.notification1_date">
								<div v-if="slotProps.data.notification1_days" style="font-size:3em;" :class="slotProps.data.notification1_days > 30 ? 'fg-success' : slotProps.data.notification1_days > 1 ? 'fg-warning' : 'fg-danger'">{{ slotProps.data.notification1_days }}</div>
								<div>
									<small>{{ slotProps.data.notification1_date | moment("D MMMM YYYY") }}</small>
								</div>
							</div>
						</template>
					</Column>
					<Column field="notification2_days" header="2nd Notificaction" :sortable="true">
						<template #body="slotProps">
							<div v-if="slotProps.data.notification2_date">
								<div v-if="slotProps.data.notification2_days" style="font-size:3em;" :class="slotProps.data.notification2_days > 30 ? 'fg-success' : slotProps.data.notification2_days > 1 ? 'fg-warning' : 'fg-danger'">{{ slotProps.data.notification2_days }}</div>
								<div>
									<small>{{ slotProps.data.notification2_date | moment("D MMMM YYYY") }}</small>
								</div>
							</div>
						</template>
					</Column>
					<Column field="notification3_days" header="3rd Notificaction" :sortable="true">
						<template #body="slotProps">
							<div v-if="slotProps.data.notification3_date">
								<div v-if="slotProps.data.notification3_days" style="font-size:3em;" :class="slotProps.data.notification3_days > 30 ? 'fg-success' : slotProps.data.notification3_days > 1 ? 'fg-warning' : 'fg-danger'">{{ slotProps.data.notification3_days }}</div>
								<div>
									<small>{{ slotProps.data.notification3_date | moment("D MMMM YYYY") }}</small>
								</div>
							</div>
						</template>
					</Column>
					<Column field="id" header="">
						<template #body="slotProps"><Button class="p-button-sm" @click="showContractDetails(slotProps.data, 'details')" label="Details" /> </template>
					</Column>
				</DataTable>
			</div>
		</div>
		<Dialog header="Contract Details" :visible.sync="contractDetailsDisplay" :modal="true">
			<div class="" style="min-height:400px;width:1200px;max-width:98%;">
				<TabView>
					<TabPanel header="Details" :active.sync="tabActive['details']">
						<div class="p-datatable-striped p-datatable p-component">
							<div class="p-datatable-wrapper">
								<table>
									<tbody class="p-datatable-tbody" style="font-size:0.8em;">
										<tr v-if="contract.servicecenter_id">
											<td class="p-text-bold p-pr-6" style="width:33%;">Service center</td>
											<td>{{ contract.servicecenter_id.name }}</td>
										</tr>
										<tr v-if="contract.government_registrationnumber">
											<td class="p-text-bold p-pr-6" style="width:33%;">Government registration<br />number (BE)</td>
											<td>{{ contract.government_registrationnumber }}</td>
										</tr>
										<tr>
											<td class="p-text-bold p-pr-6">Contract name</td>
											<td>{{ contract.name }}</td>
										</tr>
										<tr>
											<td class="p-text-bold p-pr-6">Starting date</td>
											<td>{{ contract.date_start | moment("D MMMM YYYY") }}</td>
										</tr>
										<tr>
											<td class="p-text-bold p-pr-6">End of contract</td>
											<td>{{ contract.date_end | moment("D MMMM YYYY") }}</td>
										</tr>
										<tr>
											<td class="p-text-bold p-pr-6">Cancelation date 1</td>
											<td>{{ contract.date_cancelation1 | moment("D MMMM YYYY") }}</td>
										</tr>
										<tr>
											<td class="p-text-bold p-pr-6">Cancelation date 2</td>
											<td>{{ contract.date_cancelation2 | moment("D MMMM YYYY") }}</td>
										</tr>
										<tr>
											<td class="p-text-bold p-pr-6">Date elongate</td>
											<td>{{ contract.date_elongate | moment("D MMMM YYYY") }}</td>
										</tr>

										<tr>
											<td class="p-text-bold p-pr-6">1st notification</td>
											<td>
												{{ contract.notification1_date | moment("D MMMM YYYY") }} <span v-if="contract.notification1_days" :class="contract.notification1_days <= 0 ? 'fg-danger' : 'fg-success'">({{ contract.notification1_days }})</span>
											</td>
										</tr>
										<tr>
											<td class="p-text-bold p-pr-6">2nd notification</td>
											<td>
												{{ contract.notification2_date | moment("D MMMM YYYY") }} <span v-if="contract.notification2_days" :class="contract.notification2_days <= 0 ? 'fg-danger' : 'fg-success'">({{ contract.notification2_days }})</span>
											</td>
										</tr>
										<tr>
											<td class="p-text-bold p-pr-6">3rd notification</td>
											<td>
												{{ contract.notification3_date | moment("D MMMM YYYY") }} <span v-if="contract.notification3_days" :class="contract.notification3_days <= 0 ? 'fg-danger' : 'fg-success'">({{ contract.notification3_days }})</span>
											</td>
										</tr>
										<tr>
											<td class="p-text-bold p-pr-6">Comments</td>
											<td>
												<div v-html="newLinesToBR(contract.comments)"></div>
											</td>
										</tr>
										<tr v-if="contract.files && contract.files[0] && contract.files[0].directus_files_id">
											<td class="p-text-bold p-pr-6">Contract</td>
											<td>
												<Button v-for="file in contract.files" :key="file.id" :label="file.directus_files_id.title" icon="pi pi-file-pdf" class="p-button p-button-sm p-button-outlined p-mr-3 p-mb-3" @click="downloadFile(file.directus_files_id)" />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</TabPanel>
					<TabPanel header="Contract owner" style="font-size:0.8em;" :active.sync="tabActive['owner']">
						<div v-if="contract.contract_owner_company_id">
							<div class="p-mb-4">
								<div>
									<h3>{{ contract.contract_owner_company_id.name }}</h3>
								</div>
								<div>{{ contract.contract_owner_company_id.address1 }}</div>
								<div>{{ contract.contract_owner_company_id.address2 }}</div>
								<div>{{ contract.contract_owner_company_id.postcalcode }} {{ contract.contract_owner_company_id.city }}</div>
							</div>
							<div>{{ contract.contract_owner_company_id.name }}</div>
							<div>
								<a :href="'mailto:' + contract.contract_owner_company_id.email">{{ contract.contract_owner_company_id.email }}</a>
							</div>
							<div>{{ contract.contract_owner_company_id.phone }}</div>
						</div>
					</TabPanel>
					<TabPanel header="Operational contact" style="font-size:0.8em;" :active.sync="tabActive['operational']">
						<div v-if="contract.contract_operator_contact_id && contract.contract_operator_contact_id.contract_company_id">
							<div class="p-mb-4">
								<div>
									<h3>{{ contract.contract_operator_contact_id.contract_company_id.name }}</h3>
								</div>
								<div>{{ contract.contract_operator_contact_id.contract_company_id.address1 }}</div>
								<div>{{ contract.contract_operator_contact_id.contract_company_id.address2 }}</div>
								<div>{{ contract.contract_operator_contact_id.contract_company_id.postcalcode }} {{ contract.contract_operator_contact_id.contract_company_id.city }}</div>
							</div>
							<div>{{ contract.contract_operator_contact_id.name }}</div>
							<div>
								<a :href="'mailto:' + contract.contract_operator_contact_id.email">{{ contract.contract_operator_contact_id.email }}</a>
							</div>
							<div>{{ contract.contract_operator_contact_id.phone }}</div>
						</div>
					</TabPanel>
					<TabPanel header="Costs" style="font-size:0.8em;" :active.sync="tabActive['costs']">
						<div>
							<div class="p-datatable-striped p-datatable p-component">
								<div class="p-datatable-wrapper">
									<table>
										<tbody class="p-datatable-tbody" style="font-size:0.8em;">
											<tr v-if="contract.costs_current">
												<td class="p-text-bold p-pr-6" style="width:33%;">Current costs</td>
												<td>{{ contract.costs_current | toCurrency }}</td>
											</tr>
											<tr v-if="contract.costs_last_contract">
												<td class="p-text-bold p-pr-6" style="width:33%;">Costs last contract</td>
												<td>{{ contract.costs_last_contract | toCurrency }}</td>
											</tr>
											<tr v-if="contract.costs_new_contract">
												<td class="p-text-bold p-pr-6" style="width:33%;">Costs new contract</td>
												<td>{{ contract.costs_new_contract | toCurrency }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</TabPanel>
					<TabPanel header="Action plan" style="font-size:0.8em;" :active.sync="tabActive['actionplan']">
						<div v-if="actionPlans">
							<div class="p-datatable-striped p-datatable p-component" v-for="(actionPlan, index) in actionPlans" :key="index">
								<div class="p-datatable-wrapper">
									<div v-if="1 == 2">
										<div v-for="(field, key) in actionPlan" :key="key">{{ key }}</div>
									</div>
									<table>
										<tbody class="p-datatable-tbody" style="font-size:0.8em;">
											<tr>
												<td>name</td>
												<td>{{ actionPlan.name }}</td>
											</tr>
											<tr>
												<td>ligging_ssc</td>
												<td>{{ actionPlan.ligging_ssc }}</td>
											</tr>
											<tr>
												<td>bereikbaarheid</td>
												<td>{{ actionPlan.bereikbaarheid }}</td>
											</tr>
											<tr>
												<td>voldoende_parkeerplaatsen</td>
												<td>{{ actionPlan.voldoende_parkeerplaatsen }}</td>
											</tr>
											<tr>
												<td>positie_tov_de_concurent</td>
												<td>{{ actionPlan.positie_tov_de_concurent }}</td>
											</tr>
											<tr>
												<td>positie_tov_klanten</td>
												<td>{{ actionPlan.positie_tov_klanten }}</td>
											</tr>
											<tr>
												<td>voldoet_de_locatie</td>
												<td>{{ actionPlan.voldoet_de_locatie }}</td>
											</tr>
											<tr>
												<td>zoekgebied</td>
												<td>{{ actionPlan.zoekgebied }}</td>
											</tr>
											<tr>
												<td>keuzemogelijkheden</td>
												<td>{{ actionPlan.keuzemogelijkheden }}</td>
											</tr>
											<tr>
												<td>opmerking_locatieonderzoek</td>
												<td>{{ actionPlan.opmerking_locatieonderzoek }}</td>
											</tr>
											<tr>
												<td>huidige_m2_winkelruimte</td>
												<td>{{ actionPlan.huidige_m2_winkelruimte }}</td>
											</tr>
											<tr>
												<td>huidige_m2_kantoorruimte</td>
												<td>{{ actionPlan.huidige_m2_kantoorruimte }}</td>
											</tr>
											<tr>
												<td>huidige_m2_magazijn</td>
												<td>{{ actionPlan.huidige_m2_magazijn }}</td>
											</tr>
											<tr>
												<td>buitenkluis_aanwezig</td>
												<td>{{ actionPlan.buitenkluis_aanwezig }}</td>
											</tr>
											<tr>
												<td>opmerking_inventarisatie</td>
												<td>{{ actionPlan.opmerking_inventarisatie }}</td>
											</tr>
											<tr>
												<td>hub_locatie</td>
												<td>{{ actionPlan.hub_locatie }}</td>
											</tr>
											<tr>
												<td>wat_is_de_gewenste_m2_winkelruimte</td>
												<td>{{ actionPlan.wat_is_de_gewenste_m2_winkelruimte }}</td>
											</tr>
											<tr>
												<td>kantoorruimte_gewenst</td>
												<td>{{ actionPlan.kantoorruimte_gewenst }}</td>
											</tr>
											<tr>
												<td>magazijn_aantal_palletplaatsen</td>
												<td>{{ actionPlan.magazijn_aantal_palletplaatsen }}</td>
											</tr>
											<tr>
												<td>vergaderrruimte_gewenst</td>
												<td>{{ actionPlan.vergaderrruimte_gewenst }}</td>
											</tr>
											<tr>
												<td>buitenkluis_noodzakelijk</td>
												<td>{{ actionPlan.buitenkluis_noodzakelijk }}</td>
											</tr>
											<tr>
												<td>opmerking_behoefte</td>
												<td>{{ actionPlan.opmerking_behoefte }}</td>
											</tr>
											<tr>
												<td>toegang_entree_deur</td>
												<td>{{ actionPlan.toegang_entree_deur }}</td>
											</tr>
											<tr>
												<td>exterieur_schilderwerk</td>
												<td>{{ actionPlan.exterieur_schilderwerk }}</td>
											</tr>
											<tr>
												<td>technisch_advies_aanwezig</td>
												<td>{{ actionPlan.technisch_advies_aanwezig }}</td>
											</tr>
											<tr>
												<td>staat_vd_verwarming</td>
												<td>{{ actionPlan.staat_vd_verwarming }}</td>
											</tr>
											<tr>
												<td>verwarmingsketel_jaartal</td>
												<td>{{ actionPlan.verwarmingsketel_jaartal }}</td>
											</tr>
											<tr>
												<td>energie_level</td>
												<td>{{ actionPlan.energie_level }}</td>
											</tr>
											<tr>
												<td>staat_vd_vloer</td>
												<td>{{ actionPlan.staat_vd_vloer }}</td>
											</tr>
											<tr>
												<td>staat_van_keuken</td>
												<td>{{ actionPlan.staat_van_keuken }}</td>
											</tr>
											<tr>
												<td>infrastructuur_veiligheid_compleet</td>
												<td>{{ actionPlan.infrastructuur_veiligheid_compleet }}</td>
											</tr>
											<tr>
												<td>opmerking_staat_van_gebouw</td>
												<td>{{ actionPlan.opmerking_staat_van_gebouw }}</td>
											</tr>
											<tr>
												<td>voldoet_inrichting_aan_nieuw_concept</td>
												<td>{{ actionPlan.voldoet_inrichting_aan_nieuw_concept }}</td>
											</tr>
											<tr>
												<td>voldoet_huidige_gevelreclame_aan_huisstijl</td>
												<td>{{ actionPlan.voldoet_huidige_gevelreclame_aan_huisstijl }}</td>
											</tr>
											<tr>
												<td>kosten_aanpassen_naar_nieuw_concept_gevelreclame</td>
												<td>{{ actionPlan.kosten_aanpassen_naar_nieuw_concept_gevelreclame }}</td>
											</tr>
											<tr>
												<td>kosten_aanpassen_naar_nieuw_concept_inrichting</td>
												<td>{{ actionPlan.kosten_aanpassen_naar_nieuw_concept_inrichting }}</td>
											</tr>
											<tr>
												<td>huurrpijs_huidge_locatie</td>
												<td>{{ actionPlan.huurrpijs_huidge_locatie }}</td>
											</tr>
											<tr>
												<td>nieuwe_huurprijs_huidge_locatie</td>
												<td>{{ actionPlan.nieuwe_huurprijs_huidge_locatie }}</td>
											</tr>
											<tr>
												<td>huurprijs_alternatieve_locatie_zelfde_m2_1</td>
												<td>{{ actionPlan.huurprijs_alternatieve_locatie_zelfde_m2_1 }}</td>
											</tr>
											<tr>
												<td>huurprijs_alternatieve_locatie_zelfde_m2_2</td>
												<td>{{ actionPlan.huurprijs_alternatieve_locatie_zelfde_m2_2 }}</td>
											</tr>
											<tr>
												<td>huurprijs_alternatieve_locatie_zelfde_m2_3</td>
												<td>{{ actionPlan.huurprijs_alternatieve_locatie_zelfde_m2_3 }}</td>
											</tr>
											<tr>
												<td>waardering_locatie_1</td>
												<td>{{ actionPlan.waardering_locatie_1 }}</td>
											</tr>
											<tr>
												<td>waardering_locatie_2</td>
												<td>{{ actionPlan.waardering_locatie_2 }}</td>
											</tr>
											<tr>
												<td>waardering_locatie_3</td>
												<td>{{ actionPlan.waardering_locatie_3 }}</td>
											</tr>
											<tr>
												<td>huurprijs_alternatieve_locaties_gewenste_m2_1</td>
												<td>{{ actionPlan.huurprijs_alternatieve_locaties_gewenste_m2_1 }}</td>
											</tr>
											<tr>
												<td>huurprijs_alternatieve_locaties_gewenste_m2_2</td>
												<td>{{ actionPlan.huurprijs_alternatieve_locaties_gewenste_m2_2 }}</td>
											</tr>
											<tr>
												<td>huurprijs_alternatieve_locaties_gewenste_m2_3</td>
												<td>{{ actionPlan.huurprijs_alternatieve_locaties_gewenste_m2_3 }}</td>
											</tr>
											<tr>
												<td>waardering_locatie_gewenst_1</td>
												<td>{{ actionPlan.waardering_locatie_gewenst_1 }}</td>
											</tr>
											<tr>
												<td>waardering_locatie_gewenst_2</td>
												<td>{{ actionPlan.waardering_locatie_gewenst_2 }}</td>
											</tr>
											<tr>
												<td>waardering_locatie_gewenst_3</td>
												<td>{{ actionPlan.waardering_locatie_gewenst_3 }}</td>
											</tr>
											<tr>
												<td>opmerking_financieel</td>
												<td>{{ actionPlan.opmerking_financieel }}</td>
											</tr>
											<tr>
												<td>incentives_huurder_aantal_maanden_huurvrij</td>
												<td>{{ actionPlan.incentives_huurder_aantal_maanden_huurvrij }}</td>
											</tr>
											<tr>
												<td>kosten_inrichting_ssc_volgens_highway</td>
												<td>{{ actionPlan.kosten_inrichting_ssc_volgens_highway }}</td>
											</tr>
											<tr>
												<td>verhuiskosten</td>
												<td>{{ actionPlan.verhuiskosten }}</td>
											</tr>
											<tr>
												<td>financiele_bu_casus</td>
												<td>{{ actionPlan.financiele_bu_casus }}</td>
											</tr>
											<tr>
												<td>kosten_opleveren_huiidge_locatie</td>
												<td>{{ actionPlan.kosten_opleveren_huiidge_locatie }}</td>
											</tr>
											<tr>
												<td>opmerking_ssc_manager</td>
												<td>{{ actionPlan.opmerking_ssc_manager }}</td>
											</tr>
											<tr>
												<td>opmerking_regiomanager</td>
												<td>{{ actionPlan.opmerking_regiomanager }}</td>
											</tr>
											<tr>
												<td>opmerking_richard_van_heck</td>
												<td>{{ actionPlan.opmerking_richard_van_heck }}</td>
											</tr>
											<tr>
												<td>opmerking_edwin_snel</td>
												<td>{{ actionPlan.opmerking_edwin_snel }}</td>
											</tr>
											<tr>
												<td>opmerking_overig_1</td>
												<td>{{ actionPlan.opmerking_overig_1 }}</td>
											</tr>
											<tr>
												<td>opmerking_overig_2</td>
												<td>{{ actionPlan.opmerking_overig_2 }}</td>
											</tr>
											<tr>
												<td>opmerking_overig_3</td>
												<td>{{ actionPlan.opmerking_overig_3 }}</td>
											</tr>
											<tr>
												<td>huur_opzeggen</td>
												<td>{{ actionPlan.huur_opzeggen }}</td>
											</tr>
											<tr>
												<td>huur_verlengen_onder_condities</td>
												<td>{{ actionPlan.huur_verlengen_onder_condities }}</td>
											</tr>
											<tr>
												<td>gespreksverslag_mail_onderhandeling</td>
												<td>{{ actionPlan.gespreksverslag_mail_onderhandeling }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</TabPanel>
					<TabPanel header="Rent indexation" style="font-size:0.8em;" :active.sync="tabActive['rentindexation']">
						<div>
							<div class="p-datatable-striped p-datatable p-component">
								<div class="p-datatable-wrapper">
									<table>
										<tbody class="p-datatable-tbody" style="font-size:0.8em;">
											<tr v-if="contract.costs_lease">
												<td class="p-text-bold p-pr-6">Lease costs</td>
												<td>{{ contract.costs_lease | toCurrency }} / {{ contract.costs_lease_frequency }}</td>
											</tr>

											<tr v-if="contract.service_description">
												<td class="p-text-bold p-pr-6">Service description</td>
												<td>{{ contract.service_description }}</td>
											</tr>

											<tr v-if="contract.service_costs">
												<td class="p-text-bold p-pr-6">Service costs</td>
												<td>{{ contract.service_costs | toCurrency }}</td>
											</tr>

											<tr v-if="contract.costs_rent">
												<td class="p-text-bold p-pr-6">Costs basic rent</td>
												<td>{{ contract.costs_rent | toCurrency }}</td>
											</tr>

											<tr v-if="contract.date_indexation">
												<td class="p-text-bold p-pr-6">Indexation date</td>
												<td>{{ contract.date_indexation | moment("D MMMM YYYY") }}</td>
											</tr>
											<tr>
												<td class="p-text-bold p-pr-6">Indexation calculation</td>
												<td>{{ contract.indexation_calculation }}</td>
											</tr>
											<tr>
												<td class="p-text-bold p-pr-6">Calculation by</td>
												<td>{{ contract.indexation_calculation_by }}</td>
											</tr>
											<tr v-if="contract.payment_type">
												<td class="p-text-bold p-pr-6">Payment type</td>
												<td>{{ contract.payment_type }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</TabPanel>
					<TabPanel header="Lucernex" style="font-size:0.8em;" :active.sync="tabActive['lucernex']">
						<div>
							<div class="p-datatable-striped p-datatable p-component">
								<div class="p-datatable-wrapper">
									<table>
										<tbody class="p-datatable-tbody" style="font-size:0.8em;">
											<tr>
												<td class="p-text-bold p-pr-6">Lucernex number</td>
												<td>{{ contract.lucernex_number }}</td>
											</tr>
											<tr>
												<td class="p-text-bold p-pr-6">Lucernex upload index number</td>
												<td>{{ contract.lucernex_upload_index_number }}</td>
											</tr>
											<tr v-if="contract.lucernex_upload_index_date">
												<td class="p-text-bold p-pr-6">Lucernex upload index date</td>
												<td>{{ contract.lucernex_upload_index_date | moment("D MMMM YYYY") }}</td>
											</tr>
											<tr>
												<td class="p-text-bold p-pr-6">Lucernex allonge number</td>
												<td>{{ contract.lucernex_allonge_number }}</td>
											</tr>
											<tr v-if="contract.lucernex_allonge_date">
												<td class="p-text-bold p-pr-6">Lucernex allonge date</td>
												<td>{{ contract.lucernex_allonge_date | moment("D MMMM YYYY") }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</TabPanel>
				</TabView>
			</div>
		</Dialog>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";
import ssclibrary from "@/services/ssclibrary/";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import DataView from "primevue/dataview";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Calendar from "primevue/calendar";
import Dialog from "primevue/dialog";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tag from "primevue/tag";

import JsonCSV from "vue-json-csv";

export default {
	components: {
		LayoutDefault,
		Directus,
		ssclibrary,

		ProgressSpinner,
		DataTable,
		Column,
		InputText,
		Card,
		Button,
		DataView,
		Toast,
		Message,
		Calendar,
		Dialog,
		TabView,
		TabPanel,
		Tag,
		JsonCSV,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			loading: true,
			hubs: [],
			contracts: [],
			actionPlans: [],
			filters: {
				"servicecenter_id.address_id.country_id.id": null,
			},
			contractDetailsDisplay: false,
			contract: {},
			tabActive: {
				details: true,
				owner: false,
				operational: false,
				costs: false,
				actionplan: false,
				rentindexation: false,
				lucernex: false,
			},
		};
	},
	props: {},
	methods: {
		filterDate(value, filter) {
			if (filter === undefined || filter === null || (typeof filter === "string" && filter.trim() === "")) {
				return true;
			}

			if (value === undefined || value === null) {
				return false;
			}

			return value === this.formatDate(filter);
		},
		formatDate(date) {
			let month = date.getMonth() + 1;
			let day = date.getDate();

			if (month < 10) {
				month = "0" + month;
			}

			if (day < 10) {
				day = "0" + day;
			}

			return date.getFullYear() + "-" + month + "-" + day;
		},
		showContractDetails(contract, tab) {
			this.actionPlans = [];
			if (contract.servicecenter_id && contract.servicecenter_id.id) {
				this.getActionPlan(contract.servicecenter_id.id);
			}
			let self = this;
			self.contract = JSON.parse(JSON.stringify(contract));
			self.contractDetailsDisplay = true;
			for (const [key, value] of Object.entries(self.tabActive)) {
				console.log(key);
				self.tabActive[key] = false;
			}
			self.tabActive[tab] = true;
		},

		downloadFile(file) {
			window.open(file.data.full_url);
		},
		tableExportData() {
			let self = this;
			let exportData = [];
			if (self.$refs["contractsDataTable"] && self.$refs["contractsDataTable"].dataToRender) {
				exportData = JSON.parse(JSON.stringify(self.$refs["contractsDataTable"].dataToRender));
				exportData.forEach((contract) => {
					delete contract.status;
					delete contract.sort;
					delete contract.modified_by;
					delete contract.modified_on;
					delete contract.created_on;
					delete contract.owner;

					if (contract.servicecenter_id) {
						contract.servicecenter = contract.servicecenter_id.name;
						contract.servicecenter_id = contract.servicecenter_id.id;
						if (contract.servicecenter_id.address_id && contract.servicecenter_id.address_id.country_id) contract.servicecenter_country = contract.servicecenter_id.address_id.country_id.country;
					}

					if (contract.contract_operator_contact_id) {
						contract.contract_operator = contract.contract_operator_contact_id.name;
						contract.contract_operator_contact_id = contract.contract_operator_contact_id.id;
					}

					if (contract.contract_owner_company_id) {
						contract.contract_owner = contract.contract_owner_company_id.name;
						contract.contract_owner_company_id = contract.contract_owner_company_id.id;
					}

					let files_str = "";
					if (contract.files) {
						contract.files.forEach((file) => {
							files_str = files_str + file.directus_files_id.data.full_url + "\n";
						});
						contract.files = files_str;
					}
					let files_actionplan_str = "";
					if (contract.files_actionplan) {
						contract.files_actionplan.forEach((file) => {
							files_actionplan_str = files_actionplan_str + file.directus_files_id.data.full_url + "\n";
						});
						contract.files_actionplan = files_actionplan_str;
					}
				});
			}

			return exportData;
		},
		findHub(id) {
			let self = this;
			var hubObject;
			_.forEach(self.hubs, (hub) => {
				hubObject = _.find(hub.servicecenters, ["servicecenter_id.id", id]);
				return _.isUndefined(hubObject);
			});
			return hubObject;
		},
		setCountry(id) {
			if (this.filters["servicecenter_id.address_id.country_id.id"] != id) {
				this.filters["servicecenter_id.address_id.country_id.id"] = id;
			} else {
				this.filters["servicecenter_id.address_id.country_id.id"] = null;
			}
		},
		newLinesToBR(str) {
			if (str) {
				return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
			}
			return "";
		},
		getActionPlan(ssc) {
			let self = this;
			Directus.client
				.getItems("actionplan", {
					fields: ["*.*"],
				})
				.then((response) => {
					self.actionPlans = response.data;
				});
		},
	},
	computed: {},
	created() {
		this.$moment.locale("en");
		let self = this;

		Directus.client
			.getItems("distribution_hub", {
				fields: ["*.*.*"],
			})
			.then((response_hub) => {
				let self = this;
				self.hubs = response_hub.data;
				console.log(self.hubs);

				Directus.client
					.getItems("contract", {
						fields: ["*.*", "*.*.*", "servicecenter_id.*.*", "servicecenter_id.address_id.country_id.*"],
					})
					.then((response) => {
						let self = this;
						self.loading = false;

						console.log(response.data);
						self.contracts = response.data;
						self.contracts.forEach((contract) => {
							contract.hub_name = null;
							contract.hub_code = null;
							if (contract.servicecenter_id && contract.servicecenter_id.id) {
								var hubObj = self.findHub(contract.servicecenter_id.id);
								if (hubObj) {
									contract.hub_name = hubObj.distribution_hub_id.name;
									contract.hub_code = hubObj.distribution_hub_id.code;
								}
							}

							contract.notice_period_date = null;
							if (contract.date_end && contract.notice_period) {
								contract.notice_period_date = self
									.$moment(contract.date_end)
									.add(contract.notice_period * -1, "months")
									.format("YYYY-MM-DD");
							}
							contract.notification1_date = null;
							contract.notification1_days = null;
							if (contract.notice_period_date && contract.notification1) {
								contract.notification1_date = self
									.$moment(contract.notice_period_date)
									.add(contract.notification1 * -1, "months")
									.format("YYYY-MM-DD");
								contract.notification1_days = self.$moment(contract.notification1_date).diff(self.$moment(), "days");
							}
							contract.notification2_date = null;
							contract.notification2_days = null;
							if (contract.notice_period_date && contract.notification2) {
								contract.notification2_date = self
									.$moment(contract.notice_period_date)
									.add(contract.notification2 * -1, "months")
									.format("YYYY-MM-DD");
								contract.notification2_days = self.$moment(contract.notification2_date).diff(self.$moment(), "days");
							}
							contract.notification3_date = null;
							contract.notification3_days = null;
							if (contract.notice_period_date && contract.notification3) {
								contract.notification3_date = self
									.$moment(contract.notice_period_date)
									.add(contract.notification3 * -1, "months")
									.format("YYYY-MM-DD");
								contract.notification3_days = self.$moment(contract.notification3_date).diff(self.$moment(), "days");
							}
							if (contract.date_elongate) {
								contract.date_elongate = self.$moment(contract.date_elongate).format("YYYY-MM-DD");
							}
							if (contract.date_cancelation1) {
								contract.date_cancelation1 = self.$moment(contract.date_cancelation1).format("YYYY-MM-DD");
							}
							if (contract.date_cancelation2) {
								contract.date_cancelation2 = self.$moment(contract.date_cancelation2).format("YYYY-MM-DD");
							}
							if (contract.date_indexation) {
								contract.date_indexation = self.$moment(contract.date_indexation).format("YYYY-MM-DD");
							}
							contract.date_indexation_days = null;
							if (contract.date_indexation) {
								contract.date_indexation_days = self.$moment(contract.date_indexation).diff(self.$moment(), "days");
							}
						});
					})
					.catch((error) => {
						console.log(error);
						if (error.code == 3) {
							self.$router.push({ name: "login" });
						}
					});
			})
			.catch((error_hub) => {
				console.log(error_hub);
				if (error_hub.code == 3) {
					self.$router.push({ name: "login" });
				}
			});
	},
	mounted: function() {},
};
</script>

<style>
.p-calendar .p-datepicker {
	min-width: 380px;
}
.p-datatable .p-datatable-thead > tr > th {
	white-space: nowrap;
}
.p-datatable .p-datatable-tbody > tr > td {
	white-space: nowrap;
}
</style>
